import React from "react";
import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";
import { useLocalization } from "gatsby-theme-i18n";
import LayoutTwo from "../../../components/layoutTwo";
import FormLayout from "../../../components/FormLayout/FormLayout"
import Seo from "../../../components/seo"
import { PageLinks } from "../../../common/site/page-static-links";
import { parse as parseSearch } from "query-string"
import FormFields from "../../../forms/career_form.json";
import FormFieldsAR from "../../../forms/career_form_AR.json";
import { useLocation } from "@reach/router"
const BreadcrumbModule = loadable(() => import("../../../components/BreadcrumbModule/BreadcrumbModule"));
const EnquiryIntro = loadable(() => import("../../../components/EnquiryIntro/EnquiryIntro"));
const CareerForm = loadable(() => import("../../../components/forms/default-form-layout"));

const ApplyJob = (props) => {
    const { t } = useTranslation();
    const { locale } = useLocalization();
    const location = useLocation()
    const searchParams = parseSearch(location.search)
    // const { data } = GetPropertyBooking(pid)
    // console.log('pid',props.location.state);
    return (
        <LayoutTwo>
            <div className="layout-padding-top">
                <BreadcrumbModule subparentname={`Career Opportunities`} subparent={PageLinks.career} parentname={t("about")} parent={PageLinks.about} pagename={t("applyjob")} />

                <EnquiryIntro 
                    // titlesm={"Book a Viewing"}
                    title={t("applyjob")}
                />

                <FormLayout><CareerForm fields={locale == "ar" ? FormFieldsAR : FormFields} classname="enquiry-form-wrapper" /></FormLayout>
            </div>
        </LayoutTwo>
    )
}

export const Head = () => <Seo title={`Apply for Real Estate Jobs ${process.env.GATSBY_DEFAULT_AREA?.charAt(0).toUpperCase() + process.env.GATSBY_DEFAULT_AREA?.slice(1).toLowerCase()}`} description={`Explore exciting career opportunities at ${process.env.GATSBY_SITE_NAME}. Join our innovative team and make an impact in the property industry. Apply today!`} />;

export default ApplyJob